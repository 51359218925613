@font-face {
  font-family: "Roboto-Black";
  src: url("assets/fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Medium-Italic";
  src: url("assets/fonts/Roboto/Roboto-Medium-Italic.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Bold";
  src: url("assets/fonts/Roboto/RobotoCondensed-Bold.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Medium";
  src: url("assets/fonts/Roboto/RobotoCondensed-Medium.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Regular";
  src: url("assets/fonts/Roboto/RobotoCondensed-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("assets/fonts/Roboto/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("assets/fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Raleway-Black";
  src: url("assets/fonts/Raleway/Raleway-Black.ttf");
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("assets/fonts/Raleway/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway-Light";
  src: url("assets/fonts/Raleway/Raleway-Light.ttf");
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("assets/fonts/Raleway/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("assets/fonts/Raleway/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Raleway-Thin";
  src: url("assets/fonts/Raleway/Raleway-Thin.ttf");
}
@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("assets/fonts/Raleway/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: "Raleway-SemiBold";
  src: url("assets/fonts/Raleway/Raleway-SemiBold.ttf");
}
.Raleway-Black {
  font-family: "Roboto-Black" !important;
  /* font-family: "Raleway-Black" !important; */
}
.Raleway-Bold {
  font-family: "Roboto-Bold" !important;
  /* font-family: "Raleway-Bold" !important; */
}
.Raleway-Light {
  font-family: "Roboto-Light" !important;
  /* font-family: "Raleway-Light" !important; */
}
.Raleway-Medium {
  font-family: "Roboto-Medium" !important;
  /* font-family: "Raleway-Medium" !important; */
}
.Raleway-Regular {
  font-family: "Roboto-Regular" !important;
  /* font-family: "Raleway-Regular" !important; */
}
.Raleway-Thin {
  font-family: "Roboto-Thin" !important;
  /* font-family: "Raleway-Thin" !important; */
}
.Raleway-ExtraBold {
  font-family: "Roboto-ExtraBold" !important;
  /* font-family: "Raleway-ExtraBold" !important; */
}
.Raleway-SemiBold {
  font-family: "Roboto-SemiBold" !important;
  /* font-family: "Raleway-SemiBold" !important; */
}
.marginLeft {
  margin-left: 10px !important;
}
/* 
====================================================================
  Loading Transition
====================================================================

 */
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #000000bd;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
  text-align: center;
}
.ctn-preloader .animation-preloader .icon {
  display: inline-block;
  position: relative;
}
.ctn-preloader .animation-preloader .txt-loading {
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #f9c9b3;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 30px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Roboto-Regular";
  letter-spacing: 12px;
  display: inline-block;
  color: #ee6931;
  position: relative;
  font-size: 50px;
  line-height: 30px;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
}

.Roboto-Black {
  font-family: "Roboto-Black" !important;
}
.Roboto-Bold {
  font-family: "Roboto-Bold" !important;
}
.RobotoCondensed-Bold {
  font-family: "RobotoCondensed-Bold" !important;
}
.RobotoCondensed-Medium {
  font-family: "RobotoCondensed-Medium" !important;
}
.RobotoCondensed-Regular {
  font-family: "RobotoCondensed-Regular" !important;
}
.Roboto-Italic {
  font-family: "Roboto-Italic" !important;
}
.Roboto-Medium {
  font-family: "Roboto-Medium" !important;
}
.Roboto-Regular {
  font-family: "Roboto-Regular" !important;
}
.Roboto-Medium-Italic {
  font-family: "Roboto-Medium-Italic" !important;
}
.side-bar-scroll:-webkit-scrollbar {
  width: 7px;
}
.side-bar-scroll::-webkit-scrollbar-track {
  background: #3026f1;
}
.side-bar-scroll::-webkit-scrollbar-thumb {
  background: #191847;
  border-radius: 5px !important;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px #191847ae, 0px 4px 5px 0px #00000024,
    0px 1px 10px 0px #191847b4;
}
.body::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track {
  background: white;
}
body::-webkit-scrollbar-thumb {
  background: #3026f1;
  border-radius: 0;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 100;
}
.padding-0 {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.selected-row {
  /* border: 1px solid red; */
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
.unselected-row {
  /* border: 1px solid red; */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
td {
  border-bottom: none !important;
}
.text_feild_small {
  height: 36px !important;
}
.disabled-bg {
  background-color: #efefef !important;
}

.ps__rail-y {
  display: none !important;
}

.border-bottom-black {
  border-bottom: 1px solid #3e3e3e;
}

.draggable-container {
  user-select: none;
}

.f-gray-scale {
  filter: grayscale(100);
  width: 35px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  transition: 1s;
  border-radius: 4;
  margin-right: 5px;
  margin-top: 2px;
  background: none;
}

.f-gray-scale:hover {
  filter: grayscale(0);
  transform: scale(1.1);
  transition: 1s;
}
